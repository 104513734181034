<style>
.header-container {
    z-index: 1030;
    border-bottom: 1px solid #034ecc;
}

    #my_blue_nav li>a:hover {
        background-color: blue;
    }

    #my_blue_nav li.active a {
        background-color: blue !important;
    }

    #privacyWrapper .privacyContent {
        margin-top: 0px;
    }

    .navbar .theme-brand li.theme-logo img {

        height: 50px;
    }

    .navbar {
        background: #ffffff;
    }

</style>

<style scoped>
.navbar .navbar-item .nav-item.dropdown.notification-dropdown .nav-link svg {
    color: rgb(37, 97, 208);
    width: 22px;
    height: 22px;
}

.navbar .navbar-item .nav-item.dropdown.notification-dropdown .dropdown-menu .media.server-log svg {
    color: #004d96;
    fill: rgb(0 63 150 / 37%);
}
</style>

<template>
    <client-only>

    <!-- <ModalWindow
        :title="modalTitle"
        ref="PaymentModal" 
        mode=""
        :showCustomButton="true"
        :customButtonCaption="nextButtonCaption"
        :onCustomButtonClick = "next"
    >
    
        <twilioSelectDID
            ref="phoneSelector" 
            v-model= "paymentParams.SelectedNumber"
            v-if="screen=='number_selection'"
        />    
        <PaymentInvoice 
            
            v-model="paymentParams.paymentSettings"
            v-if="screen=='invoice'"
            :payment_type="payment_type"
        
        />
    </ModalWindow> -->

    <div>
        <div class="header-container">
            <header class="header navbar navbar-expand" style="margin-left:1px;margin-right:1px">
                <ul class="navbar-item theme-brand flex-row text-center mt-3 ">
                        <!-- <router-link to="/"> -->
                        <a href="javascript:void(0)" @click="moveToHomePage">
                            <img src="~/assets/images/logo.png" class="navbar-logo d-md-*" alt="logo" style="height:50px;margin-top:0px;" />
                        </a>
                        <!-- </router-link> -->
                </ul>
                <!-- <ul class="navbar-item theme-brand flex-row text-center mt-3 d-block d-md-none ">
                        <router-link to="/">
                            <img src="~/assets/images/logo-mob.png" class="navbar-logo d-sm-block" alt="logo" style="height:50px;margin-top:0px;" />
                        </router-link>
                </ul> -->
             
                        
                <div class="navbar-item flex-row ms-auto" >
                    <div class="dropdown nav-item notification-dropdown btn-group ">
                        <div v-if="user.is_authorized" class="text-primary d-none d-md-block" style="font-size:13px;  text-transform: uppercase;">
                            <strong>{{ company.name }}</strong>
                        </div>
                    </div> 
                    

                    <div class="dropdown nav-item user-profile-dropdown notification-dropdown btn-group" style="z-index:1001">
                        
                        
                        
                        <a v-if="user.is_authorized" href="javascript:;" id="ddluser" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            
                            <client-only>
                                
                                <template v-if="'avatar' in user">
                                    
                                    <div class="avatar avatar-sm avatar-secondary mt-1 me-0">
                                        <img class="avatar-title rounded " :src="user.avatar" alt="avatar" />
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="avatar avatar-sm avatar-secondary me-0" :class="color">
                                        <span class="avatar-title rounded-circle">{{ user.initials }}</span>
                                    </div>
                                </template>
                            </client-only>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right navbar-nav" aria-labelledby="ddluser">
                            <li role="presentation" class="text-center">
                                <client-only>
                                <router-link to="/profile" class="dropdown-item">
                                    <h5>{{ user.firstname }} {{ user.lastname }}</h5>
                                    <h6>{{ user.email }}</h6>
                                </router-link>
                            </client-only>
                            </li>
                            <hr role="separator" aria-orientation="horizontal" size="0" class="dropdown-divider" />
                            <li role="presentation" class="text-end">
                                <button class="btn btn-sm mt-2 mb-2 me-3" @click="signOut()"> SIGN OUT </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
<!-- {{company}} -->
        
    </div>
</client-only>
<!-- {{messages}} -->
<!-- {{company}} -->
</template>

<script setup>
// import { useUserWebSocket } from '~/composables/useUserWebSocket';
const { $swal, $socket, $get} = useNuxtApp();
const router = useRouter();

import './assets/sass/elements/avatar.scss';
import '~/assets/sass/widgets/widgets.scss';


import { useUserWebSocket } from '~/composables/useUserWebSocket';

const { userMessages, initUserWebSocket } = useUserWebSocket();

const initializeUserWebSocket = () => {
    const token = sessionStorage.getItem('token');
    const account_id = sessionStorage.getItem('account_id');
    const account_user_id = sessionStorage.getItem('account_user_id');

    if (token && account_id && account_user_id) {
        initUserWebSocket(token, account_id, account_user_id);
    } else {
        console.log('Missing token, account_id, or account_user_id for user subscription');
    }
};

initializeUserWebSocket();

watch(() => userMessages.value,  async (newValue) => {
    
    try {
        let message = JSON.parse(userMessages.value);
        switch (message.app){
            case "logout":
                await signOut(); 
            break;
            case "refresh-key":
                let {token} = await $get("/api/protected/refresh-key"); 
                sessionStorage.setItem("token", token);

            break;
        }
        
    } catch (error){
        console.log(error)
    }

});

const moveToHomePage = () => {
    router.push("/company");
}

// const { messages: userMessages, init: initUserWebSocket } = useUserWebSocket();


// const initializeUserWebSocket = () => {
    
//   const token = sessionStorage.getItem('token');
//   const account_id = sessionStorage.getItem('account_id');
//   const account_user_id = sessionStorage.getItem('account_user_id');
  
//   if (token && account_id && account_user_id) {
//     initUserWebSocket(token, account_id, account_user_id);
    

//   } else {
//     console.log('Missing token, account_id, or account_user_id for user subscription');
//   }
// };
// initializeUserWebSocket();

// import { loadStripe } from '@stripe/stripe-js' 

// const backendUrl = '/api/protected/stripe/session'
// const loading = ref(false)

  

// const { $subscribe } = useNuxtApp(); // Notification web scoket
// if (process.client){
//     $subscribe(sessionStorage.getItem("account_id"));
//     console.log("Subscribed to events: ", sessionStorage.getItem("account_id"))
// }

const user = useUser();
user.value.is_authorized = true;
const dataIsChanged = useDataChanged();

// const modalTitle = ref("");
// let PaymentModal = ref(null);
// let phoneSelector = ref(null);
// let payment_type = ref("included_did");
// const nextButtonCaption = ref("NEXT: Payment details");
// const paymentParams = ref({});

const screen = ref("number_selection");


const addAdditinalPhoneNumber = () => {
    const screen = ref("number_selection");
    paymentParams.value = {paymentSettings:{subscription:5,service:"additional_did"}};
    modalTitle.value = "Purchase additional Phone Number";
    
   
    
    payment_type.value="additional_did";
    PaymentModal.value.openModal(); 
    phoneSelector.value.getRandomNumber();
    
}


// Function to subscribe to user events
// function subscribeUser() {
//   const token = sessionStorage.getItem('token'); // Ensure token is stored in sessionStorage
//   const account_id = sessionStorage.getItem('account_id');
//   const account_user_id = sessionStorage.getItem('account_user_id');

//   if (token && account_id && account_user_id) {
//     $socket.subscribeToUserEvents(token, account_id, account_user_id);
//   } else {
//     console.log('Missing token, account_id, or account_user_id for subscribing to user events');
//   }
// }

// function initializeSocket() {
//   $socket.init((error) => {
//     if (error) {
//       console.error('***************   Error initializing WebSocket:', error);
//     } else {
//       console.log(' ***************   WebSocket initialized successfully');
//       subscribeUser();
//       //   subscribeCallcenter()
//     }
//   });
// }
// subscribeUser();
// initializeSocket();

// if (process.client){
//     if ($socket) {
//         const { socket, init, subscribeToUserEvents } = $socket;
//         init((err) => {
//             if (err) {
//             console.error(err);
//             } else {
//             console.log('Initialization successful');
//             subscribeToUserEvents(sessionStorage.getItem('account_id'));
//             }
//         });
//     } else {
//         console.error('Socket plugin not found');
//     }
// }

const openStripePotal = async() => {
    let res = await $get("/api/protected/stripe/portal");
    // console.log(res.session.url);
    // navigateTo(res.session.url)
    window.location.href = res.session.url;
}

let color = ref("");

watch(() => user.value,  (newValue) => {
    if (!((newValue.firstname == undefined) && (newValue.lastname == undefined)) ) {
        user.value.initials = newValue.firstname[0]+newValue.lastname[0];
        user.value.has_avatar = false;
        color.value = getUserIdColor(newValue.email)
    }
    
}, { deep: true, immediate:true })

const company = useCompany();

let bc = useBreadcrumbs();
    // bc.value = [{ name: "Main page", is_current: true, url: "none" }];


function getUserIdColor(userId) {
    function hashCode(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    const colors = ['avatar-primary', 'avatar-secondary', 'avatar-danger']; // List of available colors
    const colorIndex = hashCode(userId) % colors.length; // Use hash code of user ID to get an index
    
    return colors[colorIndex];
}



const signOut = async () => {
    if(process.client) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('account_id')
        sessionStorage.removeItem('account_user_id')
        sessionStorage.removeItem('callcenter_id')
        sessionStorage.removeItem('callcenter_name')
        sessionStorage.removeItem('country')
        sessionStorage.removeItem('role')
    }
        var values = [],
            keys = Object.keys(localStorage),
            i = keys.length;
            while ( i-- ) {
                // values.push( localStorage.getItem(keys[i]) );
                localStorage.removeItem(keys[i])
            }

            console.log(values);

    reloadNuxtApp({
        path: "/",
        ttl: 1000, // default 10000
    });
    // await navigateTo("/login")
}

// const upgrade = () => {
//     modalTitle.value = "Upgrade account to Production";
//     paymentParams.value = {paymentSettings:{allowed:true, subscription:20, topup: 30, type:"included_did"}};
//     PaymentModal.value.openModal();
//     payment_type.value="included_did";
//     phoneSelector.value.getRandomNumber();
// }

// const next = async () => {


    
    
//     // alert(screen.value)
//     switch (screen.value){
//         case "number_selection":
//             screen.value = "invoice";
//             nextButtonCaption.value = "NEXT: Checkout"
//         break;

//         case "invoice":
//             // nextButtonCaption.value = "NEXT: Checkout"
//             sessionStorage.setItem("selectedDID", paymentParams.value.SelectedNumber.phoneNumber);
//             sessionStorage.setItem("topupAllowed", paymentParams.value.paymentSettings.allowed);
//             sessionStorage.setItem("topupAmount", paymentParams.value.paymentSettings.topup);
//             startCheckout();
//         break;

        
//     //     case "credit_card":
//     //         let token = await creitCard.value.handleSubmit();
//     //         console.log(token)
//     //     break;
//     }
//     // if (screen.value == "invoice"){

//     // }
//     // alert("Yes");
// }

// const startCheckout = async () => {
//     try {
//       loading.value = true
//       let url = backendUrl;
//       let topup_allowed = paymentParams.value.paymentSettings.allowed;
//       if (topup_allowed ){
//         url+= "?topup=true&amount="+paymentParams.value.paymentSettings.topup+"&service="+paymentParams.value.paymentSettings.service;
//       } else {
//         url+= "?topup=false&service="+paymentParams.value.paymentSettings.service;
//       }
//       // Fetch the checkout session from the backend
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': sessionStorage.getItem("token")
//         }
//       })
  
//       const { sessionId } = await response.json()
  
//       // Load Stripe.js with your publishable key
//       const stripe = await loadStripe('pk_test_51NIPLiIHvM6IbKomtCvHiQcP1TunrBOsSLrCovQGd9jSLRZsQt4ebxKduGByjWZ9Ga2xFJqOD2UrXKsZtVY1yj7q000DRYg1cK')
  
//       // Redirect to the Stripe Checkout page using the sessionId
//       const { error } = await stripe.redirectToCheckout({ sessionId })
  
//       if (error) {
//         console.error('Stripe Checkout error:', error)
//         loading.value = false
//       }
//     } catch (error) {
//       console.error('Checkout error:', error)
//       loading.value = false
//     }
//   }


</script>